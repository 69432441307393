import React, { useEffect, useState, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Icons, { LockOutlined } from '@ant-design/icons'
import { Menu, Grid, Tooltip, Spin } from 'antd'
import IntlMessage from '../util-components/IntlMessage'
import Icon from '../util-components/Icon'
import navConfig from 'configs/NavigationConfig'
import { connect } from 'react-redux'
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant'
import utils from 'utils'
import { onMobileNavToggle } from 'redux/actions/Theme'
import { ADMIN_PERMISSION_TYPE } from 'redux/constants/Auth'
import { FEATURE_MENUS, DISABLED_FEATURE_MESSAGE, zDNA_FEATURE } from 'constants/Constant'
import { fetchLicenseDetails } from '../../firebase/fireStore/licensingDbMethods'
import {
  hasLgeServiceAccess,
  getTenantId,
  getRole,
  getFirebaseUserName,
  getUserPermission,
  getEmailId,
  getCustomRole,
  getWiPermission
} from 'auth/FirebaseAuth'

import { AuthContext } from 'auth/AuthProvider'
import { LicenseStateContext, DeviceUsersStateContext } from 'App'
import { fetchDeviceCountsFromDB } from 'redux/actions/DeviceCounts'
import { useSelector } from 'react-redux'
import './Menu.css'
import { getDeviceUsersListByFilters } from '../../firebase/fireStore/deviceUsersDBMethods'
import { GoogleDriveContext } from '../../auth/GoogleDriveProvider'

const { SubMenu } = Menu
const { useBreakpoint } = Grid

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString()

const setDefaultOpen = (key) => {
  let keyList = []
  let keyString = ''
  if (key) {
    const arr = key.split('-')
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index]
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`)
      keyList.push(keyString)
    }
  }
  return keyList
}

const SideNavContent = (props) => {
  const context = useContext(AuthContext)
  const mailId = getEmailId(context.currentUser)
  const deviceCountsReducer = useSelector((state) => state.deviceCounts)
  const { licenseState, setLicenseState, isLicenseAvailable, setIsLicenseAvailable } =
    useContext(LicenseStateContext)
  const gddata = React.useContext(GoogleDriveContext)

  const userPermission = getUserPermission(context?.currentUser)

  const noAccessPermissions =
    userPermission && userPermission.permissions
      ? userPermission.permissions.filter((permission) =>
          permission.permissionString.includes('noaccess')
        )
      : []

  let {
    user,
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
    navigationConfig,
    deviceUsersState,
  } = props
  console.log('deviceUsersState: ', deviceUsersState)
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  const [spinner, setSpinner] = useState(true)

  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false)
    }
  }

  navigationConfig = deviceUsersState
    ? navigationConfig
    : navigationConfig.filter((item) => item.key !== 'deviceUsers')
  const tenantId = getTenantId(user)
  const role = getRole(user)
  console.log('SideNavContent - ' + tenantId + 'role: ' + getRole(context.currentUser))
  useEffect(() => {
    fetchLicenseDetails(tenantId)
      .then((res) => {
        if (res && res.status && res.status === 'false') setSpinner(false)

        if (res && res[zDNA_FEATURE]) {
          setIsLicenseAvailable(true)
        } else {
          setIsLicenseAvailable(false)
        }

        if (res && res[zDNA_FEATURE]) {
          res[zDNA_FEATURE]?.usedCount && res[zDNA_FEATURE]?.usedCount > 0
            ? setLicenseState(true)
            : setLicenseState(false)
        } else {
          setLicenseState(false)
        }
      })
      .finally(() => {
        setSpinner(false)
      })
  }, [])

  const getTitle = (type) => {
    switch (type) {
      case 'LICENSED':
        return DISABLED_FEATURE_MESSAGE.UNLICENSED_MENU
      case 'DISABLE_ANDROID_UPDATE':
        return DISABLED_FEATURE_MESSAGE.DISABLE_ANDROID_UPDATE_MENU
      case 'DISABLE_ANDROID_UPDATE_ZEBRA':
        return DISABLED_FEATURE_MESSAGE.DISABLE_ANDROID_UPDATE_MENU_ZEBRA
      case 'DISABLE_ANDROID_UPDATE_NON_ZEBRA':
        return DISABLED_FEATURE_MESSAGE.DISABLE_ANDROID_UPDATE_MENU_NON_ZEBRA
      case 'DISABLED_NO_ZDM_AS_DO':
        return DISABLED_FEATURE_MESSAGE.DISABLED_NO_ZDM_AS_DO
      case 'DISABLED_NOT_CONNECTED_TO_ZEBRA_SERVICES_TENANT':
        return DISABLED_FEATURE_MESSAGE.DISABLED_NOT_CONNECTED_TO_ZEBRA_SERVICES_TENANT
      case 'DISABLED_NOT_CONNECTED_TO_ZEBRA_SERVICES_ADMIN':
        return DISABLED_FEATURE_MESSAGE.DISABLED_NOT_CONNECTED_TO_ZEBRA_SERVICES_ADMIN
      case 'PERMISSION_RESTRICTED_BY_ADMIN':
        return DISABLED_FEATURE_MESSAGE.NOACCESS_PERMISSION_TOOLTIP
      default:
        return ''
    }
  }
  const getMenuItem = (item) => {
  
    const menuItem = (menu, isDisabled, resType) => {
      
      return (
        <Menu.Item
          className="customHover"
          key={menu.key}
          id={`menu_${menu.key}`}
          disabled={isDisabled}
        >
          {menu.icon ? (
            <Icon type={menu?.icon} />
          ) : menu?.iconImage ? (
            <Icons aria-level="mobile" className="anticon-mobile" component={menu.iconImage} />
          ) : null}
          <span>{setLocale(localization, menu?.title)}</span>
          {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
          {isDisabled ? (
            <Tooltip
              placement="top"
              align={{ offset: [0, 10] }}
              overlayClassName="disabled-license-tooltip menu-disabled-tooltip"
              title={getTitle(resType)}
            >
              <LockOutlined
                style={{ color: '#C1C7D0', float: 'right', lineHeight: '3rem', display: 'block' }}
              />
            </Tooltip>
          ) : null}
        </Menu.Item>
      )
    }
    let isAuthProcessCompleted =
      gddata.isConnectedToZebraServices && gddata.isConnectedToZebraServices === 'connected'
        ? true
        : false

    if (noAccessPermissions.some((permission) => permission.permissionString.includes(item.key))) {
      return menuItem(item, true, 'PERMISSION_RESTRICTED_BY_ADMIN')
    } else if (
      !isAuthProcessCompleted &&
      FEATURE_MENUS.ZEBRA_AUTHORIZED_FEATURE.includes(item.key)
    ) {
      return role === ADMIN_PERMISSION_TYPE
        ? menuItem(item, true, 'DISABLED_NOT_CONNECTED_TO_ZEBRA_SERVICES_ADMIN')
        : menuItem(item, true, 'DISABLED_NOT_CONNECTED_TO_ZEBRA_SERVICES_TENANT')
    } else if (licenseState && deviceCountsReducer.hasNoZdmAsDo) {
      return FEATURE_MENUS.ZDM_AS_DO_FEATURE.includes(item.key)
        ? menuItem(item, true, 'DISABLED_NO_ZDM_AS_DO')
        : menuItem(item, false, 'FREE')
    } else if (
      (hasLgeServiceAccess(context.currentUser) || gddata.enrollmentTokenAvailable) &&
      licenseState
    ) {
      return menuItem(item, false)
    } else if (
      licenseState &&
      !(hasLgeServiceAccess(context.currentUser) || gddata.enrollmentTokenAvailable)
    ) {
      return FEATURE_MENUS.ANDROID_UPDATE_FEATURE.includes(item.key)
        ? mailId.includes('@zebra.com') || !mailId.includes('@')
          ? menuItem(item, true, 'DISABLE_ANDROID_UPDATE_ZEBRA')
          : menuItem(item, true, 'DISABLE_ANDROID_UPDATE_NON_ZEBRA')
        : menuItem(item, false, 'FREE')
    } else {
      return FEATURE_MENUS.LICENSED_FEATURE.includes(item.key)
        ? menuItem(item, true, 'LICENSED')
        : menuItem(item, false, 'FREE')
    }
  }

  return (
    <Spin spinning={spinner} size="large">
      <Menu
        theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
        mode="inline"
        style={{ height: '100%', borderRight: 0 }}
        selectedKeys={[routeInfo?.key]}
        defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
        className={hideGroupTitle ? 'hide-group-title' : ''}
      >
        {navigationConfig.map((menu) =>
          menu.submenu.length > 0 ? (
            <Menu.ItemGroup key={menu.key} title={setLocale(localization, menu.title)}>
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                    key={subMenuFirst.key}
                    title={setLocale(localization, subMenuFirst.title)}
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? <Icon type={subMenuSecond?.icon} /> : null}
                        <span>{setLocale(localization, subMenuSecond.title)}</span>
                        <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  // subMenuFirst.key === "userManagement" && role === ADMIN_PERMISSION_TYPE ? (
                  //   <Menu.Item key={subMenuFirst.key} hidden={true} /> ) : (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                    <span>{setLocale(localization, subMenuFirst.title)}</span>
                    <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                  </Menu.Item>
                  // )
                )
              )}
            </Menu.ItemGroup>
          ) : ['roleManagement'].includes(menu.key) &&
            getCustomRole(context.currentUser) !== 'Admin' ? (
            <Menu.Item key={menu.key} hidden={true} />
          ) : (
            getMenuItem(menu)
          )
        )}
      </Menu>
    </Spin>
  )
}

const TopNavContent = (props) => {
  let { topNavColor, localization, navigationConfig, deviceUsersState } = props
  navigationConfig = deviceUsersState
    ? navigationConfig
    : navigationConfig.filter((item) => item.key !== 'deviceUsers')
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>{setLocale(localization, subMenuSecond.title)}</span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  )
}

const MenuContent =  (props) => {
  const { location } = props
  const context = useContext(AuthContext)
  const user = context?.currentUser
  const tenantId = getTenantId(user)
  const hasWIpermission = getWiPermission(user)

  let navigationConfig = hasWIpermission?navConfig(location.pathname):navConfig(location.pathname).filter((item)=> item.key!=="wiInsight")
  console.log('MenuContent - ' + getTenantId(user))
  const { deviceUsersState, setDeviceUsersState } = useContext(DeviceUsersStateContext)

  useEffect(() => {
    // navigationConfig = navConfig(location.pathName)

    navigationConfig = hasWIpermission?navConfig(location.pathname):navConfig(location.pathname).filter((item)=> item.key!=="wiInsight")
    getDeviceUsersListByFilters(tenantId, {})
      .then((res) => {
        console.log('got getDeviceUsersListByFilters')
        if (res && res.length > 0) {
          console.log('users present ', res.length)
          setDeviceUsersState(true)
        } else {
          console.log('user not present')
          setDeviceUsersState(false)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [location.pathname])

  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent
      user={user}
      navigationConfig={navigationConfig}
      deviceUsersState={deviceUsersState}
      {...props}
    />
  ) : (
    <TopNavContent
      user={user}
      navigationConfig={navigationConfig}
      deviceUsersState={deviceUsersState}
      {...props}
    />
  )
}

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme
  return { sideNavTheme, topNavColor }
}

export default withRouter(connect(mapStateToProps, { onMobileNavToggle })(MenuContent))
