import React, { lazy, Suspense, useEffect, useContext, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import { fetchLicenseDetails } from '../../firebase/fireStore/licensingDbMethods'
import { AuthContext } from 'auth/AuthProvider'
import { getTenantId, hasLgeServiceAccess, getUserPermission,getWiPermission } from 'auth/FirebaseAuth'
import { Spin } from 'antd'
import { LicenseStateContext, DeviceUsersStateContext } from 'App'
import { FEATURE_MENUS, zDNA_FEATURE } from '../../constants/Constant'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDeviceCountsFromDB } from 'redux/actions/DeviceCounts'
import { getDeviceUsersListByFilters } from '../../firebase/fireStore/deviceUsersDBMethods'
import { GoogleDriveContext } from '../../auth/GoogleDriveProvider'

export const AppViews = ({ match }) => {
  const context = useContext(AuthContext)
  const user = context?.currentUser
  const tenantId = getTenantId(user)
  const gddata = React.useContext(GoogleDriveContext)
  const dispatch = useDispatch()
  const [enableAndroidUpdateTab] = useState(hasLgeServiceAccess(context?.currentUser) || gddata.enrollmentTokenAvailable)
  const { licenseState, setLicenseState, isLicenseAvailable, setIsLicenseAvailable } = useContext(LicenseStateContext)
  const { deviceUsersState, setDeviceUsersState } = useContext(DeviceUsersStateContext)
  const deviceCountsReducer = useSelector((state) => state.deviceCounts)
  const [deviceUserState, setDeviceUserState] = useState(false)

  const [pending, setPending] = useState(true)

  const userPermission = getUserPermission(context?.currentUser)
  const noAccessPermissions = userPermission && userPermission.permissions ? userPermission.permissions.filter(permission => permission.permissionString.includes('noaccess')) : []
  const wiPermission = getWiPermission(context?.currentUser)
  useEffect(() => {
    init()
    deviceUsersStateCheck()
  }, [])

  function init() {
    console.log('AppViews ' + JSON.stringify(context?.currentUser))
    const tenantId = getTenantId(context?.currentUser)
    dispatch(fetchDeviceCountsFromDB(tenantId))
    fetchLicenseDetails(tenantId)
      .then((res) => {
        if (res && res.status && res.status === 'false') {
          setLicenseState(false)
          setIsLicenseAvailable(false)
        }
        if (res[zDNA_FEATURE]) {
          setIsLicenseAvailable(true)
        } else {
          setIsLicenseAvailable(false)
        }

        if (res && res[zDNA_FEATURE]) {
          res[zDNA_FEATURE]?.usedCount && res[zDNA_FEATURE]?.usedCount > 0
            ? setLicenseState(true)
            : setLicenseState(false)
        } else {
          setLicenseState(false)
        }
        setPending(false)
      })
      .catch((err) => {
        console.log(err)
        setPending(false)
      })
  }

  function deviceUsersStateCheck() {
    const tenantId = getTenantId(context?.currentUser)
    getDeviceUsersListByFilters(tenantId, {})
      .then((res) => {
        if (res && res.length > 0) {
          console.log("getDeviceUsersListByFilters: ", res.length)
          setDeviceUsersState(true)
        } else {
          setDeviceUsersState(false)
        }
      })
      .catch((err) => {
        console.log(err)
        setDeviceUsersState(false)
      })
  }

  if (pending && deviceCountsReducer.loading) {
    return (
      <>
        <Spin size="large" tip="Loading" />
      </>
    )
  }

  let isAuthProcessCompleted =
    gddata.isConnectedToZebraServices &&
      gddata.isConnectedToZebraServices === 'connected' &&
      gddata.zebraServiceAccountEmail
      ? true
      : false
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {!noAccessPermissions.some(permission => permission.permissionString.includes('dashboard')) ? (
          <Route path={`${match.url}/home`} component={lazy(() => import(`./home`))} />
        ) : null}
        {!noAccessPermissions.some(permission => permission.permissionString.includes('myDevice')) ? (
          <Route path={`${match.url}/myDevice`} component={lazy(() => import(`./myDevice`))} />
        ) : null}
        {!noAccessPermissions.some(permission => permission.permissionString.includes('initialSetupNew')) ? (
          <Route
            path={`${match.url}/initialSetupNew`}
            component={lazy(() => import(`./initial-setupNew/index`))}
          />
        ) : null}
        {isAuthProcessCompleted && !noAccessPermissions.some(permission => permission.permissionString.includes('licensing')) ? (
          <Route
            path={`${match.url}/licensing`}
            component={lazy(() => import(`./licensing/index`))}
          />) : null
        }

        {!noAccessPermissions.some(permission => permission.permissionString.includes('designStudio')) ? (<Route
          exact
          path={`${match.url}/designStudio`}
          component={lazy(() => import(`./design-studio`))}
        />) : null}
        {!noAccessPermissions.some(permission => permission.permissionString.includes('designStudio')) ? (<Route
          exact
          path={`${match.url}/enterpriseKeyboardBuilder`}
          component={lazy(() => import(`./enterpriseKeyboardBuilder/views/list/index`))}
        />) : null}
        {!noAccessPermissions.some(permission => permission.permissionString.includes('designStudio')) ? (<Route
          path={`${match.url}/enterpriseKeyboardBuilder/editor`}
          component={lazy(() => import(`./enterpriseKeyboardBuilder/views/editor/index.jsx`))}
        />) : null}
        {/* <Route
          exact
          path={`${match.url}/rxlogger`}
          component={lazy(() => import(`./pocRxLogger/views/index`))}
        /> */}

        <Route path={`${match.url}/authCode`} component={lazy(() => import(`./authCode/index`))} />
        <Route
          path={`${match.url}/googleAuthSuccessCallback`}
          component={lazy(() => import(`./googleAuthCallback/success`))}
        />
        <Route
          path={`${match.url}/googleAuthFailCallback`}
          component={lazy(() => import(`./googleAuthCallback/fail`))}
        />

        {deviceUsersState && !noAccessPermissions.some(permission => permission.permissionString.includes('deviceUsers')) ? (
          <Route
            path={`${match.url}/deviceUsers`}
            component={lazy(() => import(`./deviceUsers/index`))}
          />
        ) : null}

        {!noAccessPermissions.some(permission => permission.permissionString.includes('myApps')) ? (
          <Route path={`${match.url}/myApps`} component={lazy(() => import(`./myApps/index`))} />
        ) : null}


        {wiPermission && !noAccessPermissions.some(permission => permission.permissionString.includes('wirelessInsight')) ? (
          <Route path={`${match.url}/wirelessInsight`} component={lazy(() => import(`../wi-views/index`))} />
        ) : null}

        {licenseState && enableAndroidUpdateTab && isAuthProcessCompleted && !noAccessPermissions.some(permission => permission.permissionString.includes('androidUpdates')) ? (
          <Route
            path={`${match.url}/androidUpdates`}
            component={lazy(() => import(`./android-updates/index`))}
          />
        ) : null}

        {licenseState && !deviceCountsReducer.hasNoZdmAsDo && isAuthProcessCompleted && isAuthProcessCompleted && !noAccessPermissions.some(permission => permission.permissionString.includes('deviceSettings')) ? (
          <>
            <Route
              path={`${match.url}/deviceSettings`}
              component={lazy(() => import(`./device-settings/index`))}
            />
          </>
        ) : null}

        {!pending && !deviceCountsReducer.loading && (
          < Redirect from={`${match.url}`} to={`${match.url}/home`} />
        )}
      </Switch>
    </Suspense >
  )
}

export default AppViews
